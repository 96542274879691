import React from "react"

const TitleDescription = (props) => {
    const { data } = props

    let title = data?.field_title
    let description = data?.text?.processed
    return (
        <section className="section_bg pt-first-section pb-section">
            <div class="container">
                <div class="section-head">
                    <h1>{title || ''}</h1>
                </div>
            </div>
            <div className="container">
                <div className="content_card_box bg-white">
                    <div className="inner_card_content">
                        <div dangerouslySetInnerHTML={{ __html: description || "null" }} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TitleDescription