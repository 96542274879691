import React from "react"
import BtnLink from "../components/addon/Link";
import BottomSection from "../components/pageSections/homePage/homeBanner/bottomSection";

const BannerComponent = (props) => {
 
    const { data, linkSetData } = props
    let banner = data
    const bannerTitle = banner?.title || "";
    const bannerSubtitle = banner?.subtitle || "";
    const bannerAlt = banner?.image?.alt || "Hinduja Hospital";
    const bannerPic = banner?.relationships?.image?.uri?.url;
    const mobileBannerPic = banner?.relationships?.mobileBannerImage?.uri?.url || bannerPic;
    const bannerLinkText = banner?.bannerLink?.title || "";
    const bannerLinkUrl = banner?.bannerLink?.uri.includes('internal:/') ? banner?.bannerLink?.uri.replace('internal:','') : banner?.bannerLink?.uri || ''

    return (
        <>
        {bannerLinkText && bannerLinkUrl 
        ? <section className="hero_banner inner_common_banner">
            <picture className="banner_img">
                <img src={mobileBannerPic} alt={bannerAlt} className="d-md-none"/>
                <img src={bannerPic} alt={bannerAlt} className="d-md-block d-none"/>
            </picture>
            <div className="banner_content text_left text_dark">
                <div className="container">
                    <h1>{bannerTitle?.length > 0 ? bannerTitle : ""}</h1>
                    <p className="subhead">
                        {bannerSubtitle?.length > 0 ? bannerSubtitle : ""}
                    </p>
                    <p className="mt-4">
                        <a href={bannerLinkUrl?.length > 0 ? bannerLinkUrl : "/"} className="btn btn-primary">
                            {bannerLinkText?.length > 0 ? bannerLinkText : ""}
                        </a>
                    </p>
                </div>
            </div>
            <BottomSection
                content={linkSetData}
            />
        </section>
        : <section className="Inner_banner">
            <div className="Inner_banner_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="title_with_image">
                                <h1>{bannerTitle || ''}</h1>
                                {/* <img src="/assets/images/listing/find-doctors-icon.svg" alt="find doctors icon" title="find doctors icon" /> */}
                            </div>
                            <p>{bannerSubtitle || ''}</p>
                        </div>
                    </div>
                </div>
            </div>
            <picture className="hero_image text-center">
                {/* <source media="(min-width:1024px)"
                    srcset={`/assets/images/listing/banner-doctor-listing.jpg`} />
                <source media="(min-width:300px)"
                    srcset={`/assets/images/listing/mobile-banner-doctor-listing.png`} /> */}
                <img src={bannerPic} alt={bannerAlt} />
            </picture>
        </section>}
        </>
    )
}

export default BannerComponent