import React from "react"

const SectionLayout = (props) => {
    const { children, sectionClass } = props

    return (
        <section className={`py-5 ${sectionClass || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionLayout