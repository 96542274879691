import React from "react"
import { Link } from "gatsby"

const CTALinkModal = (props) => {
    const { data } = props

    return (
        <div className="serviceBoxWrap">
            <h3>Make Payments</h3>
            <div className="serviceBox">
                <div className="row">
                    {data.map((c, ci) => {
                        const title = c?.title;
                        const link = c?.link;
                        const img = c?.relationships?.icon?.uri?.url;
                        const modalData = c?.field_text?.processed ? c?.field_text?.processed.replace(/classname=/g, 'class=') : '';
                        return(
                            <div className="col-md-12 col-lg-6 paymentCardWrap" key={ci}>
                                <div className="paymentCard">
                                    <div className="imgWithText">
                                        {img &&
                                            <div className="imageSec">
                                                <img src={img} alt={title}/>
                                            </div>
                                        }
                                        {title &&
                                            <div className="textSec">
                                                <p>{title}</p>
                                            </div>
                                        }
                                    </div>
                                    <div className="btnWrap">
                                        {link &&
                                            <div className="btnSec">
                                                <Link classes="btn btn-primary" data={c}/>
                                            </div>   
                                        }
                                        {modalData &&
                                            <div className="btnSec">
                                                <a className="btn-link" data-target={`#sc${ci}`} data-toggle="modal" href="javascript:;">View Instruction</a>
                                            </div>
                                        }
                                    </div>  
                                </div>
                                <div className="modal common_modal fade pay_modal modal-center" id={`sc${ci}`} tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" dangerouslySetInnerHTML={{ __html: modalData }}></div> 
                                {/* <div className="modal common_modal fade pay_modal modal-center" id={`#sc${ci}`} tabindex="-1" aria-labelledby="basicModal" aria-hidden="true">
                                    <div dangerouslySetInnerHTML={{__html : modalData}}>
                                    </div>
                                </div> */}
                            </div>
                        )
                    })} 
                </div>  
            </div>
        </div>
    )
}

export default CTALinkModal