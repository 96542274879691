import React, { useState } from "react"

const PlayVideoInModal = (props) => {
    const { data } = props

    const [selectedVideo, setSelectedVideo] = useState(null)

    const setVideo = (videoUrl) => {
        let data_src = videoUrl.split('=')
        if(data_src[1]) {
            setSelectedVideo(`https://www.youtube.com/embed/${data_src[1]}`)
        }
    }

    return (
        <section className="section_bg pt-first-section pb-section testimonialWrap">
            <div className="container">
                {/* <div className="py-2">
                    <h2>{videoSectionText?.field_title || ''}</h2>
                    <p>{videoSectionText?.field_subtitle || ''}</p>
                </div> */}
                <div className="videoWrap">
                    <div className="row">
                        {data?.length 
                        ? data.map((video, index) => {
                            let imageUrl = video?.relationships?.image?.uri?.url
                            let videoUrl = video?.link?.uri
                            return (
                                <div className="col-md-4" key={index}>
                                    <div className="videoBox">
                                        <a href="javascript:;" onClick={() => setVideo(videoUrl)} className="video-popup" data-toggle="modal" data-target="#modal_video" data-src="q_mU0XUmlfY">
                                            <img src={imageUrl} alt={video?.image?.alt} />
                                            <span className="playIcon"><img src="/assets/images/testimonial/video-play-icon.png" alt="Video Play Icon" /></span>
                                        </a>
                                        <h4>{video?.title}</h4>
                                    </div>  
                                </div>
                            )
                        })
                        : null}
                        <div className="modal fade modal_video modal-center" id="modal_video" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                
                                    <div className="modal-body">
                                        <button onClick={() => setSelectedVideo(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">X</span>
                                        </button>
                                        <iframe width="100%" height="315" src={selectedVideo ? selectedVideo : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {data.length != videoSectionVideos.length 
                        ? <div className="col-md-12">
                            <div className="btnWrap text-center">
                                <LoadMoreButton
                                    label="Load More"
                                    callbackFunction={loadMoreVideos}
                                />
                            </div>  
                        </div>
                        : null} */}
                    </div>    
                </div>
            </div>
        </section>
    )
}

export default PlayVideoInModal