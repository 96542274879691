import React, { useEffect, useState } from "react"

import LoadMoreButton from "../components/addon/loadMoreButton"

import { DOCTORS_FILTER } from "../service/config"

const TitleModalDisplay = (props) => {
    const { data } = props

    let mainFilterArr = data

    let [defaultLimit] = useState(6)
    const [selectedLetter, setSelectedLetter] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)
    const [filterDataOG, setFilterDataOG] = useState(mainFilterArr)
    const [displayData, setDisplayData] = useState([])

    const loadMoreData = (data, zero) => {
        let dataArr = data ? data : filterDataOG
        const counter = (zero ? 0 : displayData.length) + defaultLimit
        const arr = [...dataArr.slice(zero ? 0 : displayData.length, counter)]
        if(zero) {
            setDisplayData([...arr])
        } else {
            setDisplayData([...displayData, ...arr])
        }
    }

    const searchByLetter = (letter) => {
        setSelectedLetter(letter)
        let arr = mainFilterArr.filter(el => el?.field_title.charAt(0) === letter)
        setFilterDataOG(arr)
        loadMoreData(arr, true)
    }

    const clearSearch = () => {
        setSelectedLetter(null)
        setFilterDataOG(mainFilterArr)
        loadMoreData(mainFilterArr, true)
    }

    const openModal = (el) => {
        setSelectedCard(el)
    }

    const closeModal = () => {
        setSelectedCard(null)
    }

    const onClick = (event) => {
        if (event?.target?.className.includes('disease_condition_modal_wrap')) {
            closeModal()
        }
    }

    useEffect(() => {
        if(selectedCard?.id) {
            document.body.classList.add("modal-open");
            const modal = document.getElementById(selectedCard?.id);
            if(modal) {
                modal.classList.add("show");
                modal.style.display = 'block';
            }
            
        } else {
            document.body.classList.remove("modal-open");
            const modal = document.getElementsByClassName('disease_condition_modal_wrap')
            if(modal[0]) {
                modal[0].classList.remove("show");
                modal[0].style.display = 'none';
            }
            const bg = document.getElementsByClassName('modal-backdrop')
            if(bg[0]) bg[0].remove()
        }
        setTimeout(() => {
            const bg = document.getElementsByClassName('modal-backdrop')
            if(!bg?.length && selectedCard?.id) window.document.body.insertAdjacentHTML( 'beforeend', `<div class="modal-backdrop show"></div>` );
        }, 50)
        window.addEventListener('click', onClick);
    }, [selectedCard])

    useEffect(() => {
        loadMoreData()
    },[])

    return (
        <section className="section-py searchSpeciality-wrap">
            <div className="container">
                <div className="alphabets_list">
                    <h2 className="text-center text-uppercase my-4">Search by letter</h2>
                    <ul className="d-flex m-0 p-0">
                        {
                            DOCTORS_FILTER.map((el, index) => {
                                return (
                                    <li key={index}>
                                        <a href="javascript:void(0)" className={selectedLetter === el ? "active" : ''} onClick={() => searchByLetter(el)} style={{ textDecoration: "none", color: "black" }}>{el}</a>
                                    </li>
                                )
                            })
                        }
                        {selectedLetter ? <li><a href="javascript:;" onClick={clearSearch} style={{ fontSize: "0.9rem", marginLeft: "15px" }} className="text-primary">clear search</a></li> : null}
                    </ul>
                </div>
                <div className="specialty_list_box">
                    <ul className="row">
                        {displayData?.length 
                        ? displayData.map((el, index) => {
                            return (
                                <li className="col-md-4" key={index}>
                                    <a onClick={() => openModal(el)} href="#" className="ddlink" data-target={'#'+el?.id} data-toggle="modal">
                                        <span className="link_text">{el?.field_title}</span>
                                    </a>
                                </li>
                            )
                        })
                        : null}
                    </ul>
                    {!displayData?.length
                    ? <p className="text-center">Currently there is no data.</p> 
                    : null} 
                    {filterDataOG?.length != displayData?.length 
                    ?  <div className="load_more text-center">
                        <LoadMoreButton
                            label="Load More"
                            callbackFunction={() => loadMoreData()}
                        />
                    </div>
                    : null}
                </div>
            </div>
            <div class="modal common_modal pay_modal disease_condition_modal_wrap" id={selectedCard?.id} tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal-header">
                            <h2>{selectedCard?.field_title}</h2>
                        </div>
                        <div class="modal-body">
                        <div dangerouslySetInnerHTML={{ __html: selectedCard?.text?.processed }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TitleModalDisplay

