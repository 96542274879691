import React from "react"

import Slider360 from "../components/addon/homeSlider"

const HomePageSlider = (props) => {

    const { data } = props

    return (
        <section className="section_bg section-py hp_gallery overflow-hidden">
            <div className="container">
                <h3 className="section-heading text-center">Hospital Tour</h3>
            </div>
            <div className="tab-content mt-3">
                <div className="tab-pane active" id="h_virtual_tour" role="tabpanel" aria-labelledby="h_virtual_tour">
                    {data && <Slider360 data={data} />}
                    <p className="text-center"><a href="javascript:;" className="btn btn-primary mt-4">View all</a></p>
                </div>
            </div>
        </section>
    )
}

export default HomePageSlider


