import React from "react"

const ImageComponent = (props) => {
    const { data } = props

    let imageSrc = data?.relationships?.image?.uri?.url
    let imageAlt = data?.image?.alt
    let mobileImageSrc = data?.relationships?.mobileImage?.uri?.url
    let mobileimageAlt = data?.mobileImage?.alt

    return (
        <>
            <img src={imageSrc} alt={imageAlt} />
            {/* <img src={mobileImageSrc} alt={mobileimageAlt} /> */}
        </>
    )
}

export default ImageComponent