import React from "react"
import { Link } from "gatsby"

const TwoColumnWithSeparator = (props) => {

    const { data } = props

    let column1 = data?.relationships?.field_column_1
    let column2 = data?.relationships?.field_column_2
    let field_contact = column1?.field_link?.uri?.replace("internal:", "");

    return (
        <section className="services_section common_services pb-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 ">
                        <div className="service_card">
                        <figure>
                            <img src={column1?.relationships?.field_icon?.uri?.url} alt="send a request" style={{ margin: "0 auto", display: "block" }} />
                        </figure>
                        <h3>{column1?.field_title !== undefined && column1?.field_title.length > 0 ? column1?.field_title : "Send A Request"}</h3>
                        <div className="btn_wrap">
                            <Link to={column1?.field_link?.uri ? field_contact : "/"} className="btn btn-primary">
                                {typeof (column1?.field_contact) !== "undefined" ? column1?.field_contact : "Click Here"}
                            </Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service_card">
                        <figure>
                            <img src={column2?.relationships?.field_icon?.uri?.url} alt="Call For Registration<" />
                        </figure>
                        <h3>{column2?.field_title ? column2?.field_title : "Call For Registration"}</h3>
                        <p dangerouslySetInnerHTML={{ __html: typeof (column2?.field_contact) !== "undefined" && column2?.field_contact }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TwoColumnWithSeparator