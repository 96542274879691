import React from "react"

const ImageTitleDescCard = (props) => {
    const { data } = props

    return (
        <section className="section_bg about-ages short-stay-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="three_cards_section short-stay-cards">
                            <div className="row">
                                {data?.length 
                                    ?  data.map(el => {
                                        let cardImg = el?.relationships?.image?.uri?.url ? el?.relationships?.image?.uri?.url : ''
                                        let cardImgAlt = el?.image?.alt
                                        return (
                                            <div className="col-lg-4 col-md-6" key={el?.id}>
                                                <div className="card"> 
                                                    <img src={cardImg} alt={cardImgAlt} />
                                                    <div className="card-body">
                                                    <h3>{el?.title}</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: el?.description?.processed }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageTitleDescCard


