import React from "react"
import BtnLink from "../components/addon/Link"

const ImageWithLink = (props) => {
    const { data } = props

    let hundredRupeeImage = data?.relationships?.image?.uri?.url 
    let hrc_cta = data?.bannerLink;

    return (
        <div className="btm_banner">
            <BtnLink data={{ link: hrc_cta }}>
                <img src={hundredRupeeImage} alt="hundredrupeeImage" />
            </BtnLink>
        </div>
    )
}

export default ImageWithLink

