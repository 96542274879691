import React from "react"

const DisplayTextInModal = (props) => {
    const { data } = props

    return (
        <div className="bgPlusIcon academicCommonTab">
            <div className="py-4">
                <h2>For Doctors</h2>
            </div>
            <div className="aboutContainer">
                <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                    {data?.length 
                    ? data.map(el => {
                        return (<li key={el?.id} role="presentation" className="nav-item"> <a className="nav-link active" data-toggle="tab" href={`#${el?.field_modal_id}`} role="tab" aria-controls={`${el?.field_modal_id}`} aria-selected="true">{el?.field_title}</a> </li>)
                    }) 
                    : null}
                </ul>
            </div>
            <div className="tab-content mt-3">
                {data?.length 
                    ? data.map(el => {
                        return <div key={el?.id} className="tab-pane active" id={`${el?.field_modal_id}`} role={`tabpanel_${el?.field_modal_id}`} aria-labelledby={`${el?.field_modal_id}`}>
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cardInnerWrap" dangerouslySetInnerHTML={{ __html: el?.field_description?.processed }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) 
                    : null}
            </div>
        </div> 
    )
}

export default DisplayTextInModal



