import React, { useState } from "react"

const TabComponent = (props) => {
    const { data } = props

    const [activeTab, setActiveTab] = useState(1);

    return (
        <section className="section_bg py-3 researchMainWrap">
            <div className="researchCommonTab">
                <div className="container">
                    <div className="bgPlusIcon">
                        <div className="py-4">
                            <h2>Research Department</h2>
                        </div>  
                        <div className="container aboutContainer">
                            <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                                {
                                typeof (data) !== "undefined" && data.map((el, index) => {
                                    return (
                                    <li role="presentation" className="nav-item" key={index}>
                                        <a
                                        onClick={() => setActiveTab(index + 1)}
                                        className={`nav-link ${activeTab === index + 1 ? 'active' : ''}`}
                                        href={`#${el?.field_title}`}
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls={`${el?.field_title}`}
                                        aria-selected="true"
                                        >
                                        {el?.field_title}
                                        </a>
                                    </li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                        <div className="container">
                            <div className="tab-content mt-3">
                                {
                                typeof (data) !== "undefined" && data.map((el, index) => {

                                    const data = typeof (el?.text?.processed) !== "undefined" && el?.text?.processed.length > 0 ? el?.text?.processed : "";

                                    return (
                                    <div
                                        key={index}
                                        className={`tab-pane ${activeTab === index + 1 ? 'active' : ''}`}
                                        id={el?.field_title}
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                        <div className="row">
                                            <div className="col-md-12">
                                            <div className="cardInnerWrap" dangerouslySetInnerHTML={{ __html: data }} />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>  
                </div> 
            </div> 
        </section>  
    )
}

export default TabComponent



