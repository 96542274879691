import React from "react"
import { Link } from "gatsby"

const ImageWithDescription = (props) => {
    const { data } = props

    const hospitalCharityTitle = data?.title || ''
    const hospitalCharityImg = data?.relationships?.image?.uri?.url ? data?.relationships?.image?.uri?.url : '/assets/images/philanthrophy/charity-at-hospital-thumb.png'
    const hospitalCharityImgAlt = data?.image?.alt || 'Charity at the Hospital'
    const hospitalCharityDescription = data?.description?.processed || ''
    const hospitalCharityBtnTitle = data?.link?.title || ''
    const hospitalCharityBtnLink = data?.link?.uri || ''

    return (
        <div className="charity-hospital-section">
            <div className="row flex-row-reverse">
                <div className="col-md-5">
                    <div className="img-section">
                        <img src={hospitalCharityImg} alt={hospitalCharityImgAlt} />
                    </div>  
                </div>
                <div className="col-md-7">
                    <div className="para_text">
                        <h2>{hospitalCharityTitle}</h2>
                        <div dangerouslySetInnerHTML={{ __html: hospitalCharityDescription }} ></div>
                        {hospitalCharityBtnTitle && hospitalCharityBtnLink &&
                        <Link to={hospitalCharityBtnLink} className="btn btn-primary">{hospitalCharityBtnTitle}</Link>}
                    </div>
                </div>  
            </div>  
        </div>
    )
}

export default ImageWithDescription